<div class="frame">
    <h4>für www.rtg-berge.de</h4>
    <h2>I. Wer ist der Verantwortliche und wer kümmert sich um den Datenschutz?</h2>
    <p>RTG Berge e.V., Berge 3, info&#64;rtg-berge.de</p>
    <p>
        Um den Datenschutz kümmert sich die JURANDO GmbH (
        <a href="https://www.jurando.de">https://www.jurando.de</a>
        ). Fragen zum Datenschutz richten Sie bitte an
        <a href="mailto:info@rtg-berge.de">info&#64;rtg-berge.de</a>
        oder per Post unter unserer Anschrift mit dem Zusatz „Datenschutz“.
    </p>
    <h2>II. Welche Rechte haben Sie?</h2>
    <h4>Sie haben uns gegenüber folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</h4>
    <p>Recht auf Auskunft (Art. 15 DSGVO),</p>
    <p>Recht auf Berichtigung (Art. 16 DSGVO),</p>
    <p>Recht auf Löschung (Art. 17 DSGVO),</p>
    <p>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO),</p>
    <p>Recht auf Datenübertragbarkeit (Art. 20 DSGVO),</p>
    <p>Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO).</p>
    <h4>Sie können sich auch gem. Art 77 DSGVO bei einer Datenschutz-Aufsichtsbehörde über uns beschweren.</h4>
    <p>Haben Sie uns eine Einwilligung erteilt, können Sie diese jederzeit widerrufen, ohne dass die Rechtmäßigkeit der bis zum Widerruf erfolgten Verarbeitung berührt wird.</p>
    <h2>III. Zu welchen Zwecken und auf welchen Rechtsgrundlagen verarbeiten wir personenbezogene Daten?</h2>
    <h3>Besuch unserer Website</h3>
    <h4>Beim rein informatorischen Besuch unserer Website, wenn Sie uns also nicht anderweitig Informationen übermitteln, erheben wir nur diejenigen personenbezogenen Daten, die uns Ihr Browser übermittelt. Dies sind:</h4>
    <ul>
        <li>IP-Adresse Ihres Systems,</li>
        <li>Datum und Uhrzeit (inkl. Zeitzone) Ihrer Anfrage,</li>
        <li>Typ, Inhalt und Protokollversion Ihrer Anfrage (konkreter Seitenaufruf),</li>
        <li>Zugriffsstatus (HTTP-Statuscode) unseres Servers,</li>
        <li>Größe unserer Serverantwort in Bytes,</li>
        <li>Website, von der die Anfrage kommt (so genannter „Referrer“),</li>
        <li>Ihr User-Agent (Typ, Version, verwendetes Betriebssystem) und</li>
        <li>die von Ihnen aufgerufene Domain.</li>
    </ul>
    <p>Wir speichern diese Daten auch in Log-Dateien.</p>
    <p>Die genannten Daten sind für uns technisch erforderlich, um unsere Website anzuzeigen, die Stabilität und Sicherheit zu gewährleisten und unser Angebot zu optimieren.</p>
    <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO, wobei sich unser berechtigtes Interesse aus den oben genannten Zwecken ergibt.</p>
    <p>Wir löschen diese Daten spätestens nach sieben Tagen. Eine darüber hinausgehende Speicherung findet nur statt, wenn die personenbezogenen Daten entsprechend gelöscht oder anonymisiert wurden; bei IP-Adressen geschieht dies bspw. durch eine datenschutzkonforme Kürzung.</p>
    <h3>Cookies</h3>
    <p>Wir setzen auf unserer Seite Cookies ein. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem System gespeichert werden und durch welche uns bestimmte Informationen zufließen.</p>
    <p>Auf unserer Website nutzen wir transiente und persistente Cookies. Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.</p>
    <p>Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie in diesem Fall eventuell nicht alle Funktionen dieser Website nutzen können.</p>
    <p>Außerdem setzen wir Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie über einen Account bei uns verfügen. Andernfalls müssten Sie sich für jeden Besuch erneut einloggen.</p>
    <p>Der Einsatz von Cookies dient dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten und die Benutzerfreundlichkeit zu optimieren. Zum anderen setzen wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gelöscht.</p>
    <p>Bei Aufruf unserer Website machen wir Sie durch einen Cookie-Hinweis, der auf diese Datenschutzerklärung verweist, auf den Einsatz der Cookies aufmerksam. Dort können Sie auch in  die Nutzung der Cookies einwilligen.</p>
    <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO, wobei sich unser berechtigtes Interesse aus den oben genannten Zwecken ergibt. Willigen Sie in die Nutzung von Cookies ein, ist Rechtsgrundlage Art. 6 Abs. 1 lit. a DSGVO.</p>
    <h3>E-Mail-Kontakt</h3>
    <p>Nehmen Sie über die von uns angegebenen E-Mail-Adressen Kontakt mit uns auf, speichern wir die von Ihnen übermittelten personenbezogenen Daten, um Ihre Anfragen zu beantworten. Ihre Angaben können in einem Customer-Relationship-Management System ("CRM System") oder einer vergleichbaren Software gespeichert werden.</p>
    <p>Im Zuge des Transports bzw. der Zustellung Ihrer E-Mail werden zudem regelmäßig Logdaten erzeugt, die bspw. auch die IP-Adresse des von Ihnen verwendeten E-Mail-Servers enthalten. Diese Logdaten sind für die Sicherstellung des ordnungsgemäßen Betriebs unseres E-Mail-Servers notwendig und dienen zudem als Nachweis bei Serverproblemen bzw. Sicherheitsvorfällen.</p>
    <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO, wobei sich unser berechtigtes Interesse aus den oben genannten Zwecken ergibt. Zielt die Kontaktaufnahme auf einen Vertragsschluss oder erfolgt die Kontaktaufnahme im Rahmen eines bestehenden Vertragsverhältnisses, ist Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO.</p>
    <p>Die Daten werden gelöscht, wenn die Anfrage abschließend bearbeitet wurde, wenn wir nicht gesetzlich zur Aufbewahrung verpflichtet sind oder wir die Daten zur Vertragserfüllung benötigen. Daneben können Sie der Verarbeitung jederzeit widersprechen, wenn die Verarbeitung auf Grund eines berechtigten Interesses erfolgt. Die Logdaten auf unserem E-Mail-Server werden spätestens nach sieben Tagen gelöscht.</p>
    <h3>Google Analytics</h3>
    <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.</p>
    <p>Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
    <p>
        Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plug-in herunterladen und installieren:
        <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
    </p>
    <p>Diese Website verwendet Google Analytics mit der Erweiterung „_anonymizeIp()“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die personenbezogenen Daten damit umgehend gelöscht.</p>
    <p>
        Wir nutzen Google Analytics, um die Nutzung unserer Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle, in denen personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen,
        <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.
    </p>
    <p>Rechtsgrundlage für die Nutzung von Google Analytics ist Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse ergibt sich aus Optimierungs- und Marketingzwecken, für die wir die Ergebnisse von Google Analytics einsetzen. Haben Sie in die Nutzung eingewilligt, ist Rechtsgrundlage Art. 6 Abs. 1 lit. a DSGVO.</p>
    <p>Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001.</p>
    <p>
        Nutzerbedingungen:
        <a href="http://www.google.com/analytics/terms/de.html">http://www.google.com/analytics/terms/de.html</a>
    </p>
    <p>
        Übersicht zum Datenschutz:
        <a href="http://www.google.com/intl/de/analytics/learn/privacy.html">http://www.google.com/intl/de/analytics/learn/privacy.html</a>
    </p>
    <p>
        sowie die Datenschutzerklärung:
        <a href="http://www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>
    </p>
    <h3>Google Maps</h3>
    <p>Auf dieser Webseite nutzen wir das Angebot von Google Maps. Dadurch können wir Ihnen interaktive Karten direkt in der Website anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.</p>
    <p>Durch den Besuch auf der Website erhält Google die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die beim Besuch unserer Website erhobenen Daten übermittelt. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen.</p>
    <p>Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre: http://www.google.de/intl/de/policies/privacy. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.</p>
    <p>Rechtsgrundlage für den Einsatz von Google Maps ist Art. 6 Abs. 1 lit. f DSGVO, wobei sich unser berechtigtes Interesse aus den genannten Marketingzwecken ergibt.</p>
</div>