import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoardComponent } from './board/board.component';
import { ClubHistoryComponent } from './club-history/club-history.component';
import { DressageComponent } from './dressage/dressage.component';
import { LeapComponent } from './leap/leap.component';
import { VaultingComponent } from './vaulting/vaulting.component';
import { GroundsComponent } from './grounds/grounds.component';
import { FormsComponent } from './forms/forms.component';
import { ContactComponent } from './contact/contact.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { TemplateHeaderComponent } from './_template/template-header/template-header.component';
import { TemplateFooterComponent } from './_template/template-footer/template-footer.component';
import { TemplateNavComponent } from './_template/template-nav/template-nav.component';
import { HomeComponent } from './home/home.component';
import { TemplateImageSliderComponent } from './_template/template-image-slider/template-image-slider.component';
import { WebsitedataService } from './_services/websitedata.service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    BoardComponent,
    ClubHistoryComponent,
    DressageComponent,
    LeapComponent,
    VaultingComponent,
    GroundsComponent,
    FormsComponent,
    ContactComponent,
    DataProtectionComponent,
    TemplateHeaderComponent,
    TemplateFooterComponent,
    TemplateNavComponent,
    HomeComponent,
    TemplateImageSliderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    HttpClientModule
  ],
  providers: [WebsitedataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
