import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leap',
  templateUrl: './leap.component.html',
  styleUrls: ['./leap.component.sass']
})
export class LeapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
