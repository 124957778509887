import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-club-history',
  templateUrl: './club-history.component.html',
  styleUrls: ['./club-history.component.sass']
})
export class ClubHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
