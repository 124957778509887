<div class="frame">
    <h2>Dressur</h2>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/dressur-biene.jpg">
        </div>
        <div class="item text">
            <div>
                Der <span class="big">Schulbetrieb</span> auf Berge wird von 
                <span class="big">Sabine Voerster</span> geleitet. Sabine ist, 
                nach einer erfolgreichen Springreiterkarriere in ihrer Jugend, im Jahr 
                2000 in den Dressursattel umgestiegen. Mit Ihrem Erfolgspferd Palm Beach 
                V - Pöppel genannt - konnte Sie viele Siege und Platzierungen bis Klasse M 
                feiern. Für nähere Informationen oder Terminvereinbarungen steht Ihnen/Euch 
                Sabine Voerster unter der <span class="big">Telefon-Nr. 0177/7035252</span> 
                gerne zur Verfügung.
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>
                Der Unterricht findet in kleinen Gruppen auf <span class="big">Schulpferden</span> oder 
                auf <span class="big">Privatpferden</span> statt. Anfänger, Fortgeschrittene und "Profis" sind 
                herzlich willkommen. Für die ersten Reitversuche werden <span class="big">Longenstunden</span> 
                nach Vereinbarung erteilt. Je nach Leistungstand haben die Schüler die 
                Möglichkeit an Turnieren in Dressurprüfungen zu starten. Wie erfolgreich 
                Sabine mit ihrem Schulbetrieb ist, zeigen hier immer wieder die Erfolge, 
                die ihre Schüler auf diesen Turnieren erreichen.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/dressur-bine2.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/schulpferde.jpg">
        </div>
        <div class="item text">
            <div>
                Die Schulpferde:<br><span class="big">Lord Ludwig</span>, brauner Wallach, 
                Stockmaß 1,70 m, vor dem Einsatz als Schulpferd erfolgreich im Springen bis 
                Kl. M und Dressur bis Kl. L. Bis 2019 auch viele Jahre als Voltigierpferd 
                unterwegs. <span class="big">Perfekt Moment</span>, Schimmelwallach, Stockmaß 
                1,74 m, vor dem Einsatz als Schulpferd erfolgreich im Springen und Dressur 
                bis Kl. L. <span class="big">Royal Sun</span>, braune Stute, Stockmaß 1,66 m, 
                wird auf Turnieren erfolgreich im Springen bis L geritten. <span class="big">Pablo</span>, 
                Fuchspony, Stockmaß 1,46 m früher erfolgreiches Reitpony des Sohnes von Sabine 
                und Horst-Dieter. <span class="big">Prinz</span>, Schimmelpony Stockmaß 1,30 m, 
                bereits seit vielen Jahren als Schulpferd im Einsatz.
            </div>
        </div>
    </div>
</div>