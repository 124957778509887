import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-nav',
  templateUrl: './template-nav.component.html',
  styleUrls: ['./template-nav.component.sass']
})
export class TemplateNavComponent implements OnInit {

  mainStyle: string;
  clubStyle: string;
  sportStyle: string;

  constructor() {
    this.mainStyle = 'none';
    this.clubStyle = 'none';
    this.sportStyle = 'none';
  }

  ngOnInit(): void {
  }

  public showMenu1(): void {
    if (this.mainStyle === 'none' && this.clubStyle === 'none' && this.sportStyle === 'none') {
      this.mainStyle = 'flex';
    } else {
      this.mainStyle = 'none';
      this.clubStyle = 'none';
      this.sportStyle = 'none';
    }
  }

  public showClub(): void {
    this.mainStyle = 'none';
    this.clubStyle = 'flex';
  }

  public showSport(): void {
    this.mainStyle = 'none';
    this.sportStyle = 'flex';
  }
}
