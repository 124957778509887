<div class="frame">
    <h2>Kontakt</h2>
    <h3>Impressum</h3>
    <div class="adress">
        <div class="impressum">
            <div>Reit- und Turniergemeinschaft Berge e.V.</div>
            <div>Berge 3</div>
            <div>58553 Halver</div>
            <div>Telefon: 0 23 53 - 4445</div>
            <div>E-Mail: <a href="mailto:rtg.berge@gmx.de">rtg.berge&#64;gmx.de</a></div>
        </div>
        <div class="register">
            <div><strong>Registereintrag:</strong></div>
            <div>Eingetragen im Vereinsregister</div>
            <div>Registergericht: Amtsgericht Iserlohn</div>
            <div>Registernummer: VR 1656</div>
            <div>Vertreten durch den Vorstand</div>
        </div>
    </div>
    <br><br>
    <h2>Anfahrt:</h2>
    <img src="../../assets/jpg/anfahrt.jpg">
    <div class="desc">
        <div class="text">
            <p>von der B229 Teilstück zwischen Lüd.-Brügge und Halver im Ortsteil Halver-Heesfeld unten in der Senke (aus Lüd. kommend links / aus Halver kommend rechts)
                Richtung Berge abbiegen.  Dem Straßenverlauf ca. 2km folgen.</p>
            <p>von der Heerstraße Teilstück zwischen Halver-Ehringhausen und Halver bei Schmidtsiepen (von Ehringhausen rechts / aus Halver kommend links) Richtung Berge abbiegen.
                Dem Straßenverlauf ca. 1km folgen.</p>
        </div>
        <div class="maps">
            <iframe id="gmap_canvas" 
                src="https://maps.google.com/maps?q=Berge%203%2058553%20Halver&t=&z=13&ie=UTF8&iwloc=&output=embed" 
                frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
            </iframe>
            <a href="https://putlocker-is.org"></a><br>
            <a href="https://www.embedgooglemap.net"></a>
        </div>
    </div>
</div>