import { Component, OnInit } from '@angular/core';
import { WebsitedataService } from 'src/app/_services/websitedata.service';

@Component({
  selector: 'app-template-footer',
  templateUrl: './template-footer.component.html',
  styleUrls: ['./template-footer.component.sass']
})
export class TemplateFooterComponent implements OnInit {

  public texts: any;

  constructor(public client: WebsitedataService) {
    this.loadTexts();
  }

  private loadTexts(): void {
    this.client.getTexts().subscribe({
      next: (data: any) => {
        this.texts = data;
      },
      error: (error) => {
        console.log(error.error);
      }
    });
  }

  ngOnInit(): void {
  }

}
