<div class="frame">
    <h2>Formulare</h2>
    <img src="../../assets/jpg/strick.jpg">
    <h4>Hier können Sie einige wichtige Formulare als PDF-Datei herunterladen:</h4>
    <div class="formbox-frame">
        <a class="formbox" href="../../assets/files/Anmeldeformular nach DSGVO.pdf" 
            download="Anmeldeformular nach DSGVO.pdf">
            <img src="../../assets/svg/pdf-file.svg">
            <div class="text">Anmeldungsformular</div>
        </a>
        <a class="formbox" href="../../assets/files/Zum Anmeldeformular Informationspflichten_Art_13_14_DSGVO.pdf"
            download="Zum Anmeldeformular Informationspflichten_Art_13_14_DSGVO.pdf">
            <img src="../../assets/svg/pdf-file.svg">
            <div class="text">Informationsblatt zur Anmeldung</div>
        </a>
        <a class="formbox" href="../../assets/files/Beitragsordnung des Vereins RTG Berge e.V.pdf"
            download="Beitragsordnung des Vereins RTG Berge e.V.pdf">
            <img src="../../assets/svg/pdf-file.svg">
            <div class="text">Beitragsordnung</div>
        </a>
        <a class="formbox" href="../../assets/files/Satzung der RTG Berge e V.pdf"
            download="Satzung der RTG Berge e V.pdf">
            <img src="../../assets/svg/pdf-file.svg">
            <div class="text">Satzung</div>
        </a>
    </div>
</div>