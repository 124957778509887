import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BoardComponent } from './board/board.component';
import { ClubHistoryComponent } from './club-history/club-history.component';
import { ContactComponent } from './contact/contact.component';
import { DataProtectionComponent } from './data-protection/data-protection.component';
import { DressageComponent } from './dressage/dressage.component';
import { FormsComponent } from './forms/forms.component';
import { GroundsComponent } from './grounds/grounds.component';
import { HomeComponent } from './home/home.component';
import { LeapComponent } from './leap/leap.component';
import { VaultingComponent } from './vaulting/vaulting.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'vorstand', component: BoardComponent},
  {path: 'vereinsgeschichte', component: ClubHistoryComponent},
  {path: 'dressur', component: DressageComponent},
  {path: 'springen', component: LeapComponent},
  {path: 'voltigieren', component: VaultingComponent},
  {path: 'anlage', component: GroundsComponent},
  {path: 'formulare', component: FormsComponent},
  {path: 'kontakt', component: ContactComponent},
  {path: 'datenschutz', component: DataProtectionComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
