<div class="nav-container" ng-style="display: none">
    <a routerLink="/">Home</a>
    <div class="dropdown">
        <div>Unser Verein</div>
        <div class="nav-item-group">
            <a routerLink="/vorstand">Der Vorstand</a>
            <a routerLink="/vereinsgeschichte">Vereinsgeschichte</a>
        </div>
    </div>
    <div class="dropdown">
        <div>Unser Sport</div>
        <div class="nav-item-group">
            <a routerLink="/dressur">Dressur</a>
            <a routerLink="/springen">Springen</a>
            <a routerLink="/voltigieren">Voltigieren</a>
        </div>
    </div>
    <a routerLink="/anlage">Die Anlage</a>
    <a routerLink="/formulare">Formulare</a>
    <a routerLink="/kontakt">Kontakt</a>
    <a routerLink="/datenschutz">Datenschutzerklärung</a>
</div>
<div class="side-nav-container">
    <div class="button" (click)="showMenu1()">
        <svg *ngIf="mainStyle === 'none' && clubStyle === 'none' && sportStyle === 'none'" viewBox="0 0 100 80" width="40" height="40">
            <rect width="100" height="20"></rect>
            <rect y="30" width="100" height="20"></rect>
            <rect y="60" width="100" height="20"></rect>
        </svg>
        <div *ngIf="mainStyle === 'flex' || clubStyle === 'flex' || sportStyle === 'flex'">X</div>
    </div>
    <div class="side-nav">
        <div class="menu1" [ngStyle]="{'display':mainStyle}">
            <a routerLink="/" (click)="showMenu1()">Home</a>
            <div (click)="showClub()">Unser Verein</div>
            <div (click)="showSport()">Unser Sport</div>
            <a routerLink="/anlage" (click)="showMenu1()">Die Anlage</a>
            <a routerLink="/formulare" (click)="showMenu1()">Formulare</a>
            <a routerLink="/kontakt" (click)="showMenu1()">Kontakt</a>
            <a routerLink="/datenschutz" (click)="showMenu1()">Datenschutzerklärung</a>
        </div>
        <div class="nav-item-group" [ngStyle]="{'display':clubStyle}">
            <a routerLink="/vorstand" (click)="showMenu1()">Der Vorstand</a>
            <a routerLink="/vereinsgeschichte" (click)="showMenu1()">Vereinsgeschichte</a>
        </div>
        <div class="nav-item-group" [ngStyle]="{'display':sportStyle}">
            <a routerLink="/dressur" (click)="showMenu1()">Dressur</a>
            <a routerLink="/springen" (click)="showMenu1()">Springen</a>
            <a routerLink="/voltigieren" (click)="showMenu1()">Voltigieren</a>
        </div>
    </div>
</div>