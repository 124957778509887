<div class="frame">
    <h2>Die Anlage</h2>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/die-anlage/hof.jpg">
        </div>
        <div class="item text">
            <div>
                Die RTG Berge e. V. genießt seit Gründung auf der Reitanlage Berge 
                die Gastfreundschaft der 
            </div>
            <div class="big">
                Familie Voerster.
            </div>
            <div>
                Diese fühlt sich als 
                Gründungsmitglieder dem Verein besonders verbunden. 
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>
                Auf der Anlage dürfen unsere Mitglieder - neben der hellen
            </div>
            <div class="big">
                <div>Reithalle 20 x 40</div>
                <div>zwei große Außenplätze 30 x 70/20 x 60</div>
            </div>
            <div>
                nutzen. Hier findet auch regelmäßig 
                Dressur- und Springunterricht, Schulpferdebetrieb und das Voltigieren in zwei 
                Gruppen statt.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/die-anlage/halle-lang.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/die-anlage/fass.jpg">
        </div>
        <div class="item text">
            Die Pflege wird von Horst Dieter Voerster durchgeführt. Hierfür gab es als 
            Dank 2020 ein 7.400 l Fass zur Bewässerung.
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div class="big">Ein weitläufiges Gelände</div>
            <div>kann jederzeit zu entspannenden Ausritten genutzt werden.</div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/die-anlage/gelaende.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/die-anlage/casino.jpg">
        </div>
        <div class="item text">
            Nach dem Reiten laden draußen kleine Sitzgruppen oder das große Casino 
            zum gemütlichen Beisammensein ein.
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>Insgesamt können auf der Anlage</div>
            <div class="big">50 Pferde in Vollpension</div>
            <div>Platz finden. Die Boxen sind auf</div>
            <div class="big">zwei große Ställe</div>
            <div>mit je 13/16 und </div>
            <div class="big">drei kleine Ställe </div>
            <div>mit 3 - 4 Boxen verteilt. Zusätzlich gibt es</div>
            <div class="big">8 Außenboxen.</div>
            <div>Das Highlight ist der</div>
            <div class="big">rot/weiße Leuchtturm.</div>
            <div>In dem Sabines Weltdressurpferd "Pöppel" seinen wohlverdienten Ruhestand verbringt.</div> 
        </div>
        <div class="item">
            <img src="../../assets/jpg/die-anlage/leuchtturm.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/die-anlage/silo.jpg">
        </div>
        <div class="item text">
            <div class="big">Kraftfutter und Stroh</div>
            <div>werden gekauft.</div>
            <div class="big">Heu und Silage</div>
            <div>
                stammen aus eigener Ernte. Gefüttert wird täglich verlässlich durch Familie Voerster.
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>Ab dem Frühjahr steht</div>
            <div class="big">für jedes Pferd ein Weideplatz</div>
            <div>
                zur Verfügung. Hier können die Pferde auf großen Wiesen in kleinen Gruppen oder einzeln 
                das schöne Bergeleben genießen.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/die-anlage/wiese.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/die-anlage/minny.jpg">
        </div>
        <div class="item text">
            <div>Aufsicht über diese große Anlage im Grünen hat die</div>
            <div class="big">Hofhündin Minny.</div>
        </div>
    </div>
</div>