import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-image-slider',
  templateUrl: './template-image-slider.component.html',
  styleUrls: ['./template-image-slider.component.sass']
})
export class TemplateImageSliderComponent implements OnInit {

  images = this.fillImageArray();

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "autoplay": true,
    "autoplaySpeed": 3000
  }

  constructor() { }

  ngOnInit(): void {
  }

  private fillImageArray() : {img: string}[] {
    let images = [
      { img: "../../assets/jpg/geschichte/2010.1.jpg" },
      { img: "../../assets/jpg/geschichte/2010.2.jpg" },
      { img: "../../assets/jpg/geschichte/2010.3.jpg" },
      { img: "../../assets/jpg/geschichte/2010.4.jpg" },
      { img: "../../assets/jpg/geschichte/2010.5.jpg" },
      { img: "../../assets/jpg/geschichte/2010.6.jpg" },
      { img: "../../assets/jpg/geschichte/2010.7.jpg" },
      { img: "../../assets/jpg/geschichte/2010.8.png" },
      { img: "../../assets/jpg/geschichte/2010.09.jpg" },
      { img: "../../assets/jpg/geschichte/2010.10.png" },
      { img: "../../assets/jpg/geschichte/2010.11.jpg" },
      { img: "../../assets/jpg/geschichte/2010.12.jpg" },
      { img: "../../assets/jpg/geschichte/2010.13.jpg" },
      { img: "../../assets/jpg/geschichte/2010.14.jpg" },
      { img: "../../assets/jpg/geschichte/2010.15.jpg" },
      { img: "../../assets/jpg/geschichte/2010.16.jpg" },
      { img: "../../assets/jpg/geschichte/2010.17.jpg" },
      { img: "../../assets/jpg/geschichte/2010.18.jpg" },
      { img: "../../assets/jpg/geschichte/2010.19.jpg" },
      { img: "../../assets/jpg/geschichte/2010.20.jpg" },
      { img: "../../assets/jpg/geschichte/2013.1.jpg" },
      { img: "../../assets/jpg/geschichte/2013.2.jpg" },
      { img: "../../assets/jpg/geschichte/2013.3.jpg" },
      { img: "../../assets/jpg/geschichte/2013.4.jpg" },
      { img: "../../assets/jpg/geschichte/2013.5.jpg" },
      { img: "../../assets/jpg/geschichte/2013.6.jpg" },
      { img: "../../assets/jpg/geschichte/2013.7.jpg" },
      { img: "../../assets/jpg/geschichte/2013.8.jpg" },
      { img: "../../assets/jpg/geschichte/2013.9.jpg" },
      { img: "../../assets/jpg/geschichte/2013.10.jpg" },
      { img: "../../assets/jpg/geschichte/2013.11.jpg" },
      { img: "../../assets/jpg/geschichte/2013.12.jpg" },
      { img: "../../assets/jpg/geschichte/2014.1.jpg" },
      { img: "../../assets/jpg/geschichte/2014.2.jpg" },
      { img: "../../assets/jpg/geschichte/2014.3.jpg" },
      { img: "../../assets/jpg/geschichte/2014.4.png" },
      { img: "../../assets/jpg/geschichte/2014.5.png" },
      { img: "../../assets/jpg/geschichte/2014.6.png" },
      { img: "../../assets/jpg/geschichte/2014.7.jpg" },
      { img: "../../assets/jpg/geschichte/2014.8.jpg" },
      { img: "../../assets/jpg/geschichte/2014.9.jpg" },
      { img: "../../assets/jpg/geschichte/2014.10.jpg" },
      { img: "../../assets/jpg/geschichte/2014.11.jpg" },
      { img: "../../assets/jpg/geschichte/2014.12.jpg" },
      { img: "../../assets/jpg/geschichte/2015.1.jpg" },
      { img: "../../assets/jpg/geschichte/2015.2.jpg" },
      { img: "../../assets/jpg/geschichte/2015.3.jpg" },
      { img: "../../assets/jpg/geschichte/2015.4.jpg" },
      { img: "../../assets/jpg/geschichte/2015.5.jpg" },
      { img: "../../assets/jpg/geschichte/2015.6.jpg" },
      { img: "../../assets/jpg/geschichte/2016.1.png" },
      { img: "../../assets/jpg/geschichte/2016.2.jpg" },
      { img: "../../assets/jpg/geschichte/2016.3.jpg" },
      { img: "../../assets/jpg/geschichte/2016.4.jpg" },
      { img: "../../assets/jpg/geschichte/2016.5.jpg" },
      { img: "../../assets/jpg/geschichte/2016.6.jpg" },
      { img: "../../assets/jpg/geschichte/2016.7.jpg" },
      { img: "../../assets/jpg/geschichte/2016.8.jpg" },
      { img: "../../assets/jpg/geschichte/2016.9.jpg" },
      { img: "../../assets/jpg/geschichte/2016.10.png" },
      { img: "../../assets/jpg/geschichte/2016.11.jpg" },
      { img: "../../assets/jpg/geschichte/2017.1.jpg" },
      { img: "../../assets/jpg/geschichte/2017.2.jpg" },
      { img: "../../assets/jpg/geschichte/2017.3.jpg" },
      { img: "../../assets/jpg/geschichte/2017.4.jpg" },
      { img: "../../assets/jpg/geschichte/2017.5.jpg" },
      { img: "../../assets/jpg/geschichte/2017.6.png" },
      { img: "../../assets/jpg/geschichte/2017.7.jpg" },
      { img: "../../assets/jpg/geschichte/2018.1.png" },
      { img: "../../assets/jpg/geschichte/2018.2.png" },
      { img: "../../assets/jpg/geschichte/2018.3.jpg" },
      { img: "../../assets/jpg/geschichte/2019.1.jpg" },
      { img: "../../assets/jpg/geschichte/2019.2.jpg" },
      { img: "../../assets/jpg/geschichte/2019.3.jpg" },
      { img: "../../assets/jpg/geschichte/2019.4.jpg" },
      { img: "../../assets/jpg/geschichte/2019.5.jpg" },
      { img: "../../assets/jpg/geschichte/2019.6.png" },
      { img: "../../assets/jpg/geschichte/2020.1.jpg" },
      { img: "../../assets/jpg/geschichte/2020.2.jpg" },
      { img: "../../assets/jpg/geschichte/2020.3.jpg" },
      { img: "../../assets/jpg/geschichte/2020.4.jpg" },
      { img: "../../assets/jpg/geschichte/2020.5.jpg" },
      { img: "../../assets/jpg/geschichte/2020.6.jpg" },
      { img: "../../assets/jpg/geschichte/2020.7.jpg" },
      { img: "../../assets/jpg/geschichte/2020.8.jpg" },
      { img: "../../assets/jpg/geschichte/2020.9.jpg" },
      { img: "../../assets/jpg/geschichte/2020.10.jpg" },
      { img: "../../assets/jpg/geschichte/2020.11.jpg" },
      { img: "../../assets/jpg/geschichte/2020.12.jpg" },
      { img: "../../assets/jpg/geschichte/2020.13.jpg" }
    ];
    
    return images;
  }
}
