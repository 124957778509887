<footer>
    <div class="frame">
        <div class="text-background">
            <h4 class="red" *ngIf="texts">z. Zt. sind {{ texts[0].headline }} Boxen frei!</h4>
            <div class="link">
                <img src="../../../assets/svg/facebook-logo.svg">
                <a href="https://www.facebook.com/RTGBerge/?ti=as" target="_blank">Facebook</a>
            </div>
        </div>
    </div>
</footer>