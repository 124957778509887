<div class="frame">
    <h2>Vereinsgeschichte</h2>
    <app-template-image-slider></app-template-image-slider>
    <p>Am 17.03.2013 haben sich 8 Gründungsmitglieder getroffen um einen weiteren
         Reitverein in Halver zu gründen.</p>
    <p>Am 21.03.2014 feierte der Verein im Rahmen einer Mitgliederversammlung sein 1 
        jähriges Bestehen und bat zur ersten Pokalübergabe für die erfolgreichsten 
        Reiter/innen des vergangenden Jahres.</p>
    <p>Im Sommer 2016 begrüßten wir unser 100. Mitglied!</p>
    <p>Auf der Mitgliederversammlung 2019 standen einige Mitglieder des Gründungsvorstands
        nicht mehr zur Wahl, sodass erste und zweite Vorsitzende und Kassierer neu besetzt wurden.</p>
    <p>Im Jahr 2020 konnten viele unserer jungen Mitglieder erstmalig auf der Anglage die 
        Prüfung für verschiedene Reitabzeichen ablegen.</p>
</div>