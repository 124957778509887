<div class="frame">
    <h2>Der Vorstand</h2>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/vorstand/Sabrina.jpg">
        </div>
        <div class="item text">
            <div>
                Ich heiße <span class="big">Sabrina Gehrke</span>. Von klein auf bin ich mit Pferden groß geworden. Ich habe große Freude 
                daran, Jungpferde auszubilden, bin aber auch häufig im Dressursattel u. a. in der Kandaren-L zu sehen, soweit mein kleiner 
                Sohn Finn mir die Zeit dafür lässt. Bereits seit Gründung des Vereins bin ich Mitglied. Sechs Jahre habe ich die Jugend des 
                Vereins als Jugendwartin vertreten. Ab 2021 war ich als zweite Vorsitzende dabei und im April 2023 wurde ich als Nachfolgerin 
                für Heike zur <span class="big">1. Vorsitzenden</span> gewählt.
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>
                Hallo, ich bin <span class="big">Lea Berghaus</span> und seit 2019 Mitglied der RTG Berge. Ich reite bereits seit meinem 
                5. Lebensjahr und bin im Springen zu Hause. Im Jahr 2022 wurde ich zur Beisitzern gewählt und 2023 habe ich den Posten 
                als <span class="big">2. Vorsitzende</span> übernommen.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/vorstand/Lea.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/vorstand/HD.jpg">
        </div>
        <div class="item text">
            <div>
                Ich heiße <span class="big">Horst Dieter Voerster</span> - ich bin als <span class="big">Sportwart</span> bereits seit Gründung im Vorstand tätig. Der Hof, auf dem die RTG-Berge ihr zu Hause gefunden hat, 
                ist bereits seit dem 16. Jahrhundert im Besitz meiner Familie und ich habe den Betrieb 1998 als Landwirt von meinem Vater übernommen. Da der Reitsport 
                und die Pferde mein Leben sind, haben meine Frau und ich uns unseren Lebenstraum erfüllt und aus dem landwirtschaftlichen Betrieb die Reitsportanlage aufgebaut. 
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>
                Ich bin <span class="big">Martina Schneider</span> und Mutter eines Reitermädchens. Seit 15 Jahren erlebe und gestalte ich das Hof- und Vereinsleben mit. Das ist sehr unterhaltsam und spannend. 
                Auf der Mitgliederversammlung im Jahr 2021 wurde ich als <span class="big">Kassiererin</span> des Vereins gewählt.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/vorstand/Martina.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/vorstand/Hannah.jpg">
        </div>
        <div class="item text">
            <div>
                Huhu, ich heiße <span class="big">Hannah Schneider</span> und turne bereits seit 2006 auf Berge rum. Vom Voltigieren übers Reiten bis 
                hin zu jeglichen Vereinsaktionen war ich über die Jahre so gut wie immer mit dabei. Aktuell sieht man mich 
                jedoch aufgrund meines Studiums nur noch am Wochenende am Stall herumflitzen. Ich bin seit 2021 neu 
                als <span class="big">Schriftführerin</span> im Vorstand.
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>
                Mein Name ist <span class="big">Sabine Voerster</span>, mein Amt als <span class="big">Beisitzerin</span> im Vorstand bekleide ich seit Gründung der RTG-Berge e. V. im Jahr 2013. 
                Der Reitsport gehört seit dem ich Laufen kann zu meinem Leben. Neben dem Springreiten war ich auch viele Jahre im Dressursport erfolgreich. 
                Seit 35 Jahren lebe ich bereits in Berge. Gemeinsam mit Horst Dieter habe ich über viele Jahre diese wunderschöne Reitsportanlage 
                geschaffen. Fast 20 Jahre bringe ich den Kindern das Reiten auf eigenen Schulpferden bei.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/vorstand/Bine.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/vorstand/Stefan.jpg">
        </div>
        <div class="item text">
            <div>
                Mein Name ist <span class="big">Stefan Czarkowski</span>. Meine beiden pferdebegeisterten Mädels haben uns im Jahr 2022 nach Berge gebracht. 
                Ende des Jahres haben wir dann unser neues Familienmitglied "Törtchen" bekommen. Auf ihr lernen unsere beiden Töchter den Reitsport von 
                allen Seiten kennen. Da ich mich gerne ehrenamtlich engagiere, musste ich auch nicht lange überredet werden, um im Vorstand als 
                <span class="big">Beisitzer</span> seit April 2023 dabei zu sein. 
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>
                Mein Name ist <span class="big">Heike Preuß</span>. Bereits im Jahr 2013 war ich als Gründungsmitglied der RTG-Berge mit dabei und habe die 
                ersten sechs Jahre den Job als Kassierin übernommen. Nachdem ich danach vier Jahre als 1. Vorsitzende tätig war, habe ich im April 2023 
                meinen Platz für den Nachwuchs frei gemacht und stehe diesem als <span class="big">Beisitzerin</span> mit Rat und Tat zur Seite. Meine große 
                Liebe im Reitsport war meine alte Stute "Petra", die ich leider Ende 2022 nach vielen gemeinsamen Jahren verloren habe. Deshalb verbringe 
                ich weniger Zeit am Stall, sondern bin mehr mit unserem Hund Sammy unterwegs.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/vorstand/Heike.jpg">
        </div>
    </div>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/vorstand/Manou.jpg">
        </div>
        <div class="item text">
            <div>
                Hey, ich bin <span class="big">Manou Wiegand</span> und ich habe mit 10 Jahren auf Berge in Sabines Reitunterricht mit dem Reiten angefangen. 
                Seit ein paar Jahren habe ich ein eigenes Pferd und hab das Glück ein paar von Horst-Dieters und Sabines Pferden reiten und auf Turnieren 
                vorstellen zu dürfen. Auf dem Springplatz bin ich bis M erfolgreich und konnte im September 2023 mein erstes S*-Springen gehen. 
                Als ich jünger war durfte ich als Vereinskind bei den Jugendaktionen mitmachen und nun bin ich seit September 2021 als <span class="big">Jugendwartin</span> 
                selber an der Reihe, unseren Kindern und Jugendlichen zur Seite zu stehen und Aktionen zu planen.
            </div>
        </div>
    </div>
</div>