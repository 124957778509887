import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsitedataService {
  private url: string = 'https://websitedata.dr4co.de';
  private credentialId = '6565028237063ea61dc7a056';

  constructor(private http: HttpClient) { }

  public getTexts(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*'
      })
    };

    return this.http.get<any>(`${this.url}/text/get/${this.credentialId}`, httpOptions);
  }
}
