<div class="frame">
    <h2>Springen</h2>
    <div class="container">
        <div class="item">
            <img src="../../assets/jpg/trainer.jpg">
        </div>
        <div class="item text">
            <div>
                Horst-Dieter Voerster hat jahrzehntelange Turniererfahrung als 
                aktiver Reiter sammeln können. Seine Karriere hat er mit seinem 
                Erfolgspferd „Paul“ beendet, mit dem er sogar im Springen der 
                Klasse S gestartet ist. Durch die familieneigene Zucht konnte er 
                auch reichlich Erfahrung in der Ausbildung junger Pferde sammeln.
            </div>
        </div>
    </div>
    <div class="container">
        <div class="item text">
            <div>
                Stolz kann er auf viele erfolgreiche Schüler zurück blicken, 
                die das Reiten bei Sabine auf Schulpferden gelernt haben und 
                von Horst-Dieter im Springen gefördert wurden. Hierzu zählen 
                z. B. Carolin Mohler – erfolgreich in S, Manou Wiegand – erfolgreich 
                in L, Norelle van Aerschot – erfolgreich in A und Nadine 
                Kirchhoff – erfolgreich in E.
            </div>
        </div>
        <div class="item">
            <img src="../../assets/jpg/springtraining.jpg">
        </div>
    </div>
</div>